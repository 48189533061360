import PropTypes from "prop-types";
import React from "react";

import GridNew from "~components/atoms/grid_new/grid_new";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import RscHbCardArticle from "~components/molecules/rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";

import { BLOK, BLOK_ARRAY } from "../../../../prop_types";
import { wrapperStyle } from "./blog_related_articles_section.module.scss";

const GRID_SPLIT = { XXL: 2, M: 2, XS: 1 };

function BlogRelatedArticlesSection({ items, text }) {
  if (items?.length > 0) {
    return (
      <div data-testid="blog-related-articles-section" className={wrapperStyle}>
        <HorizontalDivider text={text} />
        <GridNew split={GRID_SPLIT}>
          {items?.map((item) => {
            return <RscHbCardArticle key={item.title} {...item} />;
          })}
        </GridNew>
      </div>
    );
  }
  return null;
}

BlogRelatedArticlesSection.propTypes = {
  items: PropTypes.oneOfType([
    BLOK_ARRAY,
    PropTypes.arrayOf(BLOK),
    PropTypes.node,
  ]),
  text: PropTypes.string,
};

BlogRelatedArticlesSection.defaultProps = {
  items: null,
  text: null,
};

export default BlogRelatedArticlesSection;
