import PropTypes from "prop-types";
import React from "react";

import Heading from "~components/atoms/heading/heading";
import Avatar from "~components/molecules/avatar/avatar";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";

import { AUTHOR } from "../../../../prop_types";
import {
  authorBioStyle,
  authorHeadingWrapperStyle,
  authorJobTitleStyle,
  authorStyle,
  avatarWrapperStyle,
  footerStyle,
} from "./blog_post_footer.module.scss";

function BlogPostFooter({ author, text }) {
  return (
    <div key="blogPost" className={footerStyle}>
      <HorizontalDivider variant="gray" text={text} />
      <div className={authorStyle}>
        {author?.content.avatar && (
          <div className={avatarWrapperStyle}>
            <Avatar image={author?.content.avatar} width="96" />
          </div>
        )}
        <div>
          <div className={authorHeadingWrapperStyle}>
            <Heading seoLevel="3" visualLevel={5}>
              <span itemProp="author">{author?.content.name}</span>
            </Heading>
          </div>
          <div className={authorJobTitleStyle}>{author?.content.jobTitle}</div>
          <div className={authorBioStyle}>{author?.content.bio}</div>
        </div>
      </div>
    </div>
  );
}

BlogPostFooter.propTypes = {
  author: AUTHOR,
  text: PropTypes.string,
};

BlogPostFooter.defaultProps = {
  author: null,
  text: null,
};

export default BlogPostFooter;
