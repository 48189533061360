import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import Breadcrumbs from "~components/organisms/breadcrumbs/breadcrumbs";
import {
  breadcrumbsWrapperStyle,
  wrapperStyle,
} from "./rsc_hb_page_header.module.scss";

function RscHbPageHeader({ breadcrumbsItems, title, description, size }) {
  const headingVisualSize = size === "large" ? "3" : "4";
  const headingHierarchy = size === "large" ? "1" : "3";

  const subHeadingVisualSize = "6";
  const subHeadingHierarchy = size === "large" ? "2" : "4";

  return (
    <div data-testid="rsc-hb-page-header" className={wrapperStyle}>
      {breadcrumbsItems && (
        <div className={breadcrumbsWrapperStyle}>
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
      )}
      <Heading visualLevel={headingVisualSize} seoLevel={headingHierarchy}>
        {title}
      </Heading>
      <Heading
        visualLevel={subHeadingVisualSize}
        seoLevel={subHeadingHierarchy}
        isExtraBold={false}
        isBaseFontWeight
      >
        {description}
      </Heading>
    </div>
  );
}

RscHbPageHeader.propTypes = {
  breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  description: PropTypes.string,
  size: PropTypes.oneOf(["large", "small"]),
};

RscHbPageHeader.defaultProps = {
  breadcrumbsItems: null,
  title: null,
  description: null,
  size: "large",
};

export default RscHbPageHeader;
