import React from "react";
import PropTypes from "prop-types";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import { LINK } from "../../../prop_types";
import { wrapperStyle } from "./breadcrumbs.module.scss";

const BUTTON_MARGIN = { marginBottom: "none" };

export default function Breadcrumbs({ items }) {
  return (
    <div className={wrapperStyle}>
      {items &&
        items.length > 0 &&
        items.map((item) => {
          if (item?.title && item?.link && item?.isLink) {
            return (
              <ButtonTertiary
                isFontRegular
                link={item.link}
                title={item.title}
                key={item.title}
                margin={BUTTON_MARGIN}
              />
            );
          }
          if (item?.title) {
            return (
              <ButtonTertiary
                isFontRegular
                isDisabled
                title={item.title}
                key={item.title}
                margin={BUTTON_MARGIN}
              />
            );
          }
          return null;
        })}
    </div>
  );
}

Breadcrumbs.defaultProps = {
  items: null,
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: LINK,
      isLink: PropTypes.bool,
    })
  ),
};
