import React, { useMemo, useState } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import ContentCoverArtwork from "~components/molecules/content_cover_artwork/content_cover_artwork";
import HorizontalDivider from "~components/molecules/horizontal_divider/horizontal_divider";
import RichTextWithBloks from "~components/molecules/rich_text/rich_text_with_bloks";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import JsonLd from "~components/utils/json_ld/json_ld";
import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { parseStoryblokEntry } from "~utils/storyblok/storyblok";
import { removeDefaultLanguagePrefix } from "~utils/url_utils/remove_default_language_prefix/remove_default_language_prefix";
import useSiteUrl from "~hooks/use_site_url/use_site_url";
import { ASSET, STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import BlogPostAuthorSocialSection from "./components/blog_post_author_social_section/blog_post_author_social_section";
import BlogPostFooter from "./components/blog_post_footer/blog_post_footer";
import BlogRelatedArticlesSection from "./components/blog_related_articles_section/blog_related_articles_section";
import RscHbEntryCTA from "./components/resource_hub_entry_cta/resource_hub_entry_cta";
import getBlogBreadcrumbsSchemaMarkup from "./helper_functions/get_blog_breadcrumbs_schema_markup/get_blog_breadcrumbs_schema_markup";
import getBlogPostingSchemaMarkup from "./helper_functions/get_blog_posting_schema_markup/get_blog_posting_schema_markup";
import {
  articleBodyStyle,
  outerWrapperStyle,
} from "./template_blog_article.module.scss";

export default function TemplateBlogArticle(props) {
  const { pageContext, data } = props;
  const { nodes: entryAssets } = data?.assets || [];

  const {
    breadcrumbsItems,
    category,
    contactForm,
    relatedArticlesItems,
    subNavData,
    UIStrings,
    resourceHubCtaConfig,
    lang,
  } = pageContext;

  const story = useMemo(() => {
    return parseStoryblokEntry("story", props.data.story);
  }, [props.data.story]);

  const { uiAboutTheAuthorTitle, uiRelatedArticlesTitle } = UIStrings || {};

  const {
    author: initialAuthor,
    date,
    title,
    subtitle,
    cover,
    content,
    cta,
  } = story;

  // ToDo: get page path upstream in plugin instead
  const pagePath = props.data.story.full_slug;
  const basePath = pagePath?.split("/", 2).join("/");
  const sanitisedBasePath = removeDefaultLanguagePrefix(basePath);

  // Keep internal cache of author as storyblok replaces resolved author object
  // with UID string when editing blog post. Caveat - author will only update on
  // refresh/remount
  const [author] = useState(initialAuthor);

  /* —————————————————————————————————————————————————————————————————————————————
  |                        STRUCTURED SCHEMA DATA FOR SEO                         |
  ——————————————————————————————————————————————————————————————————————————————— */
  // Add schema markup for rich snippets in Google search results
  // more info here: https://ahrefs.com/blog/rich-snippets/

  /* —————————————————————————————————————————————————————————————
  |                  BLOG POSTING SCHEMA DATA                   |
  ——————————————————————————————————————————————————————————————— */
  // Informs search engines the page contains a blog posting, and shows it in a special way in search results
  // https://schema.org/BlogPosting

  const origin = useSiteUrl();
  const currentPagePath = createUrlPathFromArray([origin, pagePath]);
  const coverArtworkAsset = entryAssets?.find((gatsbyImageObject) => {
    return gatsbyImageObject.id === `File_${cover?.id}`;
  });
  const absoluteImagePath = coverArtworkAsset
    ? `${origin}${coverArtworkAsset.publicURL}`
    : "";
  const blogPostingSchemaMarkup = useMemo(() => {
    return getBlogPostingSchemaMarkup({
      title,
      absoluteImagePath,
      origin,
      date,
      currentPagePath,
    });
  }, [absoluteImagePath, currentPagePath, date, origin, title]);

  /* —————————————————————————————————————————————————————————————
  |                   BREADCRUMBS SCHEMA DATA                   |
  ——————————————————————————————————————————————————————————————— */
  // Allows search engines to understand the relationship of this page to other pages in the hierarchy
  // https://schema.org/breadcrumb
  const blogBreadcrumbsSchemaMarkup = getBlogBreadcrumbsSchemaMarkup({
    category,
    title: "TrueLayer blog",
    basePath: sanitisedBasePath,
  });

  // ——————————————————————————————————————————————————————————————————————————————
  //      RETURN STATEMENT
  // ——————————————————————————————————————————————————————————————————————————————

  return (
    <TemplateGlobalEntry
      {...props}
      entryAssets={entryAssets}
      subNavData={subNavData}
      headerColor="blue"
    >
      {/* ———— SCHEMA MARKUP DATA ———— */}
      <JsonLd data={blogPostingSchemaMarkup} />
      <JsonLd data={blogBreadcrumbsSchemaMarkup} />

      <div className={outerWrapperStyle}>
        <Contain small>
          <RscHbPageHeader
            breadcrumbsItems={breadcrumbsItems}
            title={title}
            description={subtitle}
          />

          <ContentCoverArtwork cover={cover} />
          <BlogPostAuthorSocialSection
            title={title}
            author={author}
            date={date}
          />

          <HorizontalDivider />

          <div className={articleBodyStyle}>
            <RichTextWithBloks
              size="large"
              text={content}
              {...data.story.content}
            />
          </div>
        </Contain>

        <Contain small>
          <RscHbEntryCTA
            cta={cta}
            resourceHubCtaConfig={resourceHubCtaConfig}
          />
          <BlogPostFooter author={author} text={uiAboutTheAuthorTitle} />
          <BlogRelatedArticlesSection
            items={relatedArticlesItems?.items}
            text={uiRelatedArticlesTitle}
          />
        </Contain>
      </div>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateBlogArticle.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    category: PropTypes.string,
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    lang: PropTypes.string,
    next: STORYBLOK_ENTRY,
    previous: STORYBLOK_ENTRY,
    resourceHubCtaConfig: PropTypes.shape({}),
    relatedArticlesItems: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
    UIStrings: PropTypes.shape({
      uiNextTitle: PropTypes.string,
      uiPreviousTitle: PropTypes.string,
      uiAboutTheAuthorTitle: PropTypes.string,
      uiRelatedArticlesTitle: PropTypes.string,
    }),
  }).isRequired,
};

export const query = graphql`
  fragment blogPostStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }
  fragment allBlogPostAssets on Query {
    assets: allFile(filter: { id: { in: $allBlogPostArtwork } }) {
      nodes {
        id
        publicURL
        fields {
          aspectRatio
        }
        childImageSharp {
          gatsbyImageData(width: 992)
        }
      }
    }
  }
  query blogPostQuery(
    $id: String
    $langRegex: String
    $allBlogPostArtwork: [String]
  ) {
    ...blogPostStory
    ...allBlogPostAssets
    ...global
  }
`;
