import React from "react";

import PropTypes from "prop-types";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import useMatchMedia from "~hooks/use_match_media/use_match_media";

import * as classes from "./social_share_popup.module.scss";

const STYLES = {
  backgroundColor: "transparent",
  fill: "transparent",
};
export default function SocialSharePopup({ title, link, column }) {
  const isXsScreen = useMatchMedia({ min: "XS", max: "S" });

  // Can be styled as a column for the sticky popup use case in the blog post
  const classNames = [classes.socialShareButtonsWrapper];
  if (column) {
    classNames.push(classes.column);
  }

  return (
    <>
      <div className={classes.socialSharePopupWrapper}>
        <div className={classNames.join(" ")}>
          <LinkedinShareButton
            url={link}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={classes.socialButton}
            data-tip="Share on LinkedIn"
          >
            <LinkedinIcon
              bgStyle={STYLES}
              borderRadius={5}
              iconFillColor="#f7ab1b"
              size={32}
            />
          </LinkedinShareButton>
          <TwitterShareButton
            url={link}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={classes.socialButton}
            data-tip="Share on Twitter"
          >
            <TwitterIcon
              bgStyle={STYLES}
              borderRadius={5}
              iconFillColor="#f7ab1b"
              size={32}
            />
          </TwitterShareButton>
          <FacebookShareButton
            url={link}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className={classes.socialButton}
            data-tip="Share on Facebook"
          >
            <FacebookIcon
              bgStyle={STYLES}
              borderRadius={5}
              iconFillColor="#f7ab1b"
              size={32}
            />
          </FacebookShareButton>
          {!isXsScreen && (
            <RedditShareButton
              url={link}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className={classes.socialButton}
              data-tip="Share on Reddit"
            >
              <RedditIcon
                bgStyle={STYLES}
                borderRadius={5}
                iconFillColor="#f7ab1b"
                size={32}
              />
            </RedditShareButton>
          )}
        </div>
      </div>
    </>
  );
}

SocialSharePopup.defaultProps = {
  title: "TrueLayer | Opening up finance",
  link: "https://truelayer.com/blog",
  column: false,
};

SocialSharePopup.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  column: PropTypes.bool,
};
