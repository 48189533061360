import PropTypes from "prop-types";
import React from "react";

const JsonLd = ({ data }) => {
  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

export default JsonLd;

JsonLd.defaultProps = {
  data: null,
};

JsonLd.propTypes = {
  data: PropTypes.shape({}),
};
