import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import { COLOR } from "../../../prop_types";
import * as classes from "./horizontal_divider.module.scss";

// ToDo: (AMG) don't define colors as JS objects
const CLOUD_BURST_50 = "#7991a2";
const LIGHT_GRAY = "#f4f7f9";
const WHITE_TEXT_COLOR = "#ffffff";

export default function HorizontalDivider({
  lineColor,
  lineWeight,
  noMargin,
  size,
  text,
  textColor,
  variant,
}) {
  const classNames = [classes.horizontalDivider];
  let lineFinalColor;
  let textFinalColor;

  switch (variant) {
    case "white":
      lineFinalColor = LIGHT_GRAY;
      textFinalColor = WHITE_TEXT_COLOR;

      break;
    case "gray":
      lineFinalColor = LIGHT_GRAY;
      textFinalColor = CLOUD_BURST_50;

      break;
    default:
      lineFinalColor = lineColor?.color || lineColor || LIGHT_GRAY;
      textFinalColor = textColor?.color || textColor || CLOUD_BURST_50;
      break;
  }

  const lineStyle = {
    color: lineFinalColor,
  };

  const textStyle = {
    color: textFinalColor,
  };

  if (variant) {
    classNames.push(classes[`variant_${variant}`]);
  }
  if (lineWeight) {
    classNames.push(classes[`lineWeight_${lineWeight}`]);
  }

  if (noMargin) {
    classNames.push(classes.noMargin);
  }

  return (
    <div className={classNames.join(" ")} style={lineStyle}>
      {text && (
        <div className={classes.text} style={textStyle}>
          <Heading
            visualLevel={size === "regular" ? "7" : "6"}
            seoLevel="3"
            isUppercase
            noMargin
          >
            {text}
          </Heading>
        </div>
      )}
    </div>
  );
}

HorizontalDivider.defaultProps = {
  lineColor: null,
  lineWeight: "thin",
  noMargin: false,
  size: "regular",
  text: null,
  textColor: null,
  variant: "",
};

HorizontalDivider.propTypes = {
  lineColor: PropTypes.oneOfType([COLOR, PropTypes.string]),
  lineWeight: PropTypes.oneOf(["thin", "thick"]),
  noMargin: PropTypes.bool,
  size: PropTypes.oneOf(["regular", "large", ""]),
  text: PropTypes.string,
  textColor: PropTypes.oneOfType([COLOR, PropTypes.string]),
  variant: PropTypes.oneOf(["gray", "white", ""]),
};
