import PropTypes from "prop-types";
import React from "react";

import SocialSharePopup from "~components/molecules/social_share_popup/social_share_popup";

import formatDate from "~utils/date_utils/format_date/format_date";

import { AUTHOR } from "../../../../prop_types";
import BlogPostAuthorSection from "../blog_post_author_section/blog_post_author_section";
import { authorSocialSectionWrapperStyle } from "./blog_post_author_social_section.module.scss";

export default function BlogPostAuthorSocialSection({ author, date, title }) {
  const metadata = formatDate(date);

  return (
    <div className={authorSocialSectionWrapperStyle}>
      <BlogPostAuthorSection author={author} metadata={metadata} />
      <SocialSharePopup
        title={title}
        link={typeof window !== "undefined" && window.location.href}
      />
    </div>
  );
}

BlogPostAuthorSocialSection.propTypes = {
  author: AUTHOR.isRequired,

  date: PropTypes.string.isRequired,

  title: PropTypes.string.isRequired,
};
