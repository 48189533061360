import PropTypes from "prop-types";
import React from "react";

import Avatar from "~components/molecules/avatar/avatar";

import { AUTHOR } from "../../../../prop_types";
import {
  authorSectionInnerStyle,
  authorSectionWrapperStyle,
} from "./blog_post_author_section.module.scss";

function BlogPostAuthorSection({ author, metadata }) {
  return (
    <div className={authorSectionWrapperStyle}>
      {author && <Avatar width="48" image={author?.content.avatar} />}
      <div className={authorSectionInnerStyle}>
        {author && (
          <div>
            <span itemProp="author">
              {`${author.content.name}, ${author.content.jobTitle}`}
            </span>
          </div>
        )}
        {metadata && <div>{metadata}</div>}
      </div>
    </div>
  );
}

BlogPostAuthorSection.propTypes = {
  author: AUTHOR,
  metadata: PropTypes.string,
};

BlogPostAuthorSection.defaultProps = {
  author: null,
  metadata: null,
};

export default BlogPostAuthorSection;
