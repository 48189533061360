// extracted by mini-css-extract-plugin
export var active = "social_share_popup-module--active--oRG60";
export var caret = "social_share_popup-module--caret--Z0I5+";
export var column = "social_share_popup-module--column--MrU1D";
export var disabledStyle = "social_share_popup-module--disabledStyle--8Ft4G";
export var socialButton = "social_share_popup-module--socialButton--hnWvr";
export var socialButtonIcon = "social_share_popup-module--socialButtonIcon--3CeYF";
export var socialShareButtonsWrapper = "social_share_popup-module--socialShareButtonsWrapper--emhGc";
export var socialSharePopupItem = "social_share_popup-module--socialSharePopupItem--X8ED+";
export var socialSharePopupWrapper = "social_share_popup-module--socialSharePopupWrapper--8Vb7V";
export var tooltip = "social_share_popup-module--tooltip--lsfD0";