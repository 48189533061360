import { removeDefaultLanguagePrefix } from "~utils/url_utils/remove_default_language_prefix/remove_default_language_prefix";

export default function getBlogPostingSchemaMarkup({
  title,
  absoluteImagePath,
  origin,
  date,
  currentPagePath,
}) {
  const sanitisedCurrentPagePath = removeDefaultLanguagePrefix(currentPagePath);

  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
    },
    headline: title,
    image: absoluteImagePath,
    url: sanitisedCurrentPagePath,
    author: {
      "@type": "Organization",
      name: "TrueLayer",
      url: origin,
    },
    publisher: {
      "@type": "Organization",
      name: "TrueLayer",
      logo: {
        "@type": "ImageObject",
        url: `${origin}/images/logo/TrueLayer.png`,
      },
    },
    datePublished: date,
    dateModified: date,
  };
}
