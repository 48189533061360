import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import BackgroundImage from "~components/molecules/background_image/background_image";
import AspectRatio from "~components/utils/aspect_ratio/aspect_ratio";
import { MEDIA } from "../../../../prop_types";
import {
  imageInnerStyle,
  isActiveStyle,
} from "./rsc_hb_card_cover_image.module.scss";

function RscHubCardCoverImage({
  cover,
  isActive,
  roundTop,
  roundBottom,
  portrait,
  hasCard,
}) {
  if (!cover) {
    return null;
  }

  const innerClassNames = classNames(imageInnerStyle, {
    [isActiveStyle]: isActive,
  });

  let aspectRatio = 0.5625;

  if (portrait) {
    aspectRatio = 1.4135;
  }
  if (hasCard) {
    return (
      <Card isFullWidth isRaised={isActive}>
        <AspectRatio ratio={aspectRatio}>
          <div data-testid="rsc-hb-cover-image" className={innerClassNames}>
            <BackgroundImage
              roundTop={roundTop}
              roundBottom={roundBottom}
              image={cover}
              objectFit="cover"
            />
          </div>
        </AspectRatio>
      </Card>
    );
  }

  return (
    <AspectRatio ratio={aspectRatio}>
      <div data-testid="rsc-hb-cover-image" className={innerClassNames}>
        <BackgroundImage
          roundTop={roundTop}
          roundBottom={roundBottom}
          image={cover}
          objectFit="cover"
        />
      </div>
    </AspectRatio>
  );
}

RscHubCardCoverImage.propTypes = {
  cover: MEDIA.isRequired,
  isActive: PropTypes.bool,
  roundTop: PropTypes.bool,
  roundBottom: PropTypes.bool,
  portrait: PropTypes.bool,
  hasCard: PropTypes.bool,
};

RscHubCardCoverImage.defaultProps = {
  isActive: false,
  roundTop: false,
  roundBottom: false,
  portrait: false,
  hasCard: false,
};

export default RscHubCardCoverImage;
