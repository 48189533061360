import capitaliseFirstLetter from "~utils/capitalise_first_letter/capitalise_first_letter";

export default function getBlogBreadcrumbsSchemaMarkup({
  category,
  basePath,
  title,
}) {
  const categoryPretty =
    category && capitaliseFirstLetter(category.replace(/-/g, " "));

  // TOP LEVEL
  const itemListElement = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: `https://truelayer.com/`,
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "TrueLayer blog",
      item: `https://truelayer.com/${basePath}/`,
    },
    {
      "@type": "ListItem",
      position: 3,
      name: categoryPretty,
      item: `https://truelayer.com/${basePath}/${category}/`,
    },
    {
      "@type": "ListItem",
      position: 4,
      name: title,
    },
  ];

  const data = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return data;
}
