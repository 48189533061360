import React from "react";

import BackgroundImage from "~components/molecules/background_image/background_image";
import AspectRatio from "~components/utils/aspect_ratio/aspect_ratio";

import { MEDIA } from "../../../prop_types";
import { coverStyle, wrapperStyle } from "./content_cover_artwork.module.scss";

export default function ContentCoverArtwork({ cover }) {
  return (
    <div data-testid="content-cover-artwork" className={wrapperStyle}>
      <div className={coverStyle}>
        {/* {Sub nav with navigation & social share} */}
        <AspectRatio ratio={0.5625}>
          {cover?.filename && (
            <BackgroundImage image={cover} objectFit="cover" />
          )}
        </AspectRatio>
      </div>
    </div>
  );
}

ContentCoverArtwork.propTypes = {
  cover: MEDIA,
};

ContentCoverArtwork.defaultProps = {
  cover: null,
};
